@import "common/fonts";
@import "common/core";

.sf-angular {
    //@import "common/base";
    @import "common/button-groups";
    @import "common/controls";
    @import "common/dropdowns";
    @import "common/layout";
    @import "common/images";
    @import "common/grid";
    @import "common/modal";
    @import "common/tooltips";
    @import "common/ag-grid";
    @import "common/tour";
    @import "common/form-validation";
    @import "common/highlight";
    @import "common/inputs";
    @import "common/tables";
    @import "common/ag-grid";
    @import "common/highlight";
    @import "common/growls";
}

@import "../../../node_modules/ngx-toastr/toastr.css";
@import "../../../node_modules/@angular/cdk/overlay-prebuilt.css";
@import "../../../node_modules/@angular/cdk/a11y-prebuilt.css";

.overlay-container {
    position: absolute;
    top: 0em;
    right: 1em;
    min-width: 300px; //TODO: Find a variable to hook into?
    pointer-events: none;
}

// For spinner component
@keyframes spinner-line-fade-default {
    0%,
    100% {
        opacity: 0.22; /* minimum opacity */
    }
    1% {
        opacity: 1;
    }
}
