@use "../../../../../node_modules/@ag-grid-community/styles" as ag;
@import "../common/core";
@include ag.grid-styles(
    (
        theme: sf,
        foreground-color: $dark,
        //secondary-foreground-color: $dark10,  // after the enable-readable-grid-headers live config is removed
        //header-foreground-color: $dark10,     // after the enable-readable-grid-headers live config is removed
        secondary-foreground-color: $white,
        header-foreground-color: $white,
        disabled-foreground-color: $light4,
        background-color: $light2,
        //header-background-color: $light6,     // after the enable-readable-grid-headers live config is removed
        header-background-color: $dark9,
        selected-row-background-color: $interaction2,
        odd-row-background-color: $light4,
        value-change-value-highlight-background-color: #cec,
        value-change-delta-up-color: $success,
        value-change-delta-down-color: $alert,
        chip-background-color: #ecf0f1,
        range-selection-highlight-color: transparent,
        range-selection-background-color-2: rgba(33, 150, 243, 0.2),
        range-selection-background-color-3: rgba(33, 150, 243, 0.2),
        range-selection-background-color-4: rgba(33, 150, 243, 0.2),
        border-color: transparent,
        checkbox-checked-color: $black,
        checkbox-unchecked-color: $black,
        cell-horizontal-border: solid transparent,
        grid-size: 5px,
        font-family: $default-font-family,
        font-size: 16px,
        icon-font-family: "simplifile-grid",
        //icon-size: 15px,                      // after the enable-readable-grid-headers live config is removed
        icon-size: 20px,
        icon-font-code-aggregation: "\e925",
        icon-font-code-arrows: "\e90b",
        icon-font-code-asc: "\e92b",
        icon-font-code-cancel: "\e909",
        icon-font-code-chart: "\e90e",
        icon-font-code-checkbox-checked: "\e90f",
        icon-font-code-checkbox-indeterminate: "\e920",
        icon-font-code-checkbox-unchecked: "\e931",
        icon-font-code-columns: "\e91d",
        icon-font-code-contracted: "\e923",
        icon-font-code-copy: "\e914",
        icon-font-code-cross: "\e933",
        icon-font-code-desc: "\e92c",
        icon-font-code-expanded: "\e920",
        icon-font-code-eye-slash: "\e91a",
        icon-font-code-eye: "\e91b",
        icon-font-code-filter: "\e906",
        icon-font-code-first: "\e900",
        icon-font-code-grip: "\e907",
        icon-font-code-group: "\e922",
        icon-font-code-last: "\e901",
        icon-font-code-left: "\e934",
        icon-font-code-loading: "\e930",
        icon-font-code-maximize: "\e919",
        icon-font-code-menu: "\e90d",
        icon-font-code-minimize: "\e913",
        icon-font-code-next: "\e937",
        icon-font-code-none: "\e90a",
        icon-font-code-not-allowed: "\e90c",
        icon-font-code-paste: "\e912",
        icon-font-code-pin: "\e932",
        icon-font-code-pivot: "\e916",
        icon-font-code-previous: "\e936",
        icon-font-code-radio-button-off: "\e911",
        icon-font-code-radio-button-on: "\e917",
        icon-font-code-right: "\e935",
        icon-font-code-save: "\e918",
        icon-font-code-small-down: "\e902",
        icon-font-code-small-left: "\e903",
        icon-font-code-small-right: "\e904",
        icon-font-code-small-up: "\e905",
        icon-font-code-tick: "\e910",
        icon-font-code-tree-closed: "\e923",
        icon-font-code-tree-indeterminate: "\e920",
        icon-font-code-tree-open: "\e920"
    )
);

.ag-theme-sf,
.ag-fresh {
    &.enable-user-selection {
        .ag-cell {
            -webkit-user-select: text !important;
            -moz-user-select: text !important;
            -ms-user-select: text !important;
            user-select: text !important;
        }
    }

    .ag-sort-order {
        color: white;
        opacity: 0.8;
    }

    .ag-cell.no-text-overflow {
        text-overflow: inherit;
    }

    .ag-cell {
        padding-right: 0;
    }

    .ag-cell .ag-cell-value {
        position: relative;

        div:not(.dropdown-menu) {
            display: inline-block;
        }
    }

    .ag-header-cell {
        color: $white;
        .ag-header-label-icon,
        .ag-sort-indicator-icon {
            margin-left: var("--ag-grid-size");
        }
    }

    .sf-pill {
        line-height: 20px;
    }

    .ag-header {
        font-weight: bold;
        min-height: 2rem;
        .ag-checkbox-input-wrapper::after {
            color: $white;
        }
    }

    .ag-header-cell {
        border: none;
    }

    .ag-root {
        border: none;
    }

    .ag-cell {
        border: 0;
    }

    .ag-icon {
        color: inherit;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        position: relative;
        top: -2px;
    }

    .ag-group-expanded,
    .ag-group-contracted {
        margin-right: 20px;
        color: $black;
    }

    .ag-checkbox-input-wrapper {
        margin-right: var("--ag-cell-horizontal-padding");
        position: relative;
        input[type="checkbox"],
        input[type="radio"] {
            /* ==== make sure checkboxes and radio inputs actually match the size of the ag-grid icon used to cover them ===== */
            width: inherit;
            height: inherit;
            margin: 0;
        }
    }

    .ag-overlay-no-rows-wrapper {
        background-color: rgba(255, 255, 255, 0.66);
    }

    .ag-tooltip {
        font-size: 0.75rem;
        color: $light0;
        background-color: $dark7;
        border-radius: 0;
        border: 1px solid $light3;
        padding: 3px;
    }
}

.ag-dnd-ghost {
    display: none !important;
}

// remove this section after the enable-readable-grid-headers live config is removed
.enable-readable-grid-headers {
    .ag-theme-sf,
    .ag-fresh {
        .ag-header-cell {
            color: $dark10; // after the live config is removed, place this in the "@include ag.grid-styles section" --> header-foreground-color: $dark10

            .ag-header-cell-resize {
                border-right: 5px double $dark3;
            }

            &:last-child {
                .ag-header-cell-resize {
                    border-right: none;
                }
            }

            &.ag-header-cell-sortable:not(.ag-column-resizing):not(
                    .ag-column-hover
                ):not(.ag-header-cell-moving):hover {
                background-color: $interaction1;

                &.ag-header-cell-sortable[aria-sort="none"]
                    .ag-sort-indicator-container::after {
                    font-family: "simplifile-grid";
                    content: "\e938";
                    color: $interaction11;
                    margin-left: 4px;
                }
            }
        }

        .ag-header {
            font-size: 15px; // after the live config is removed, place this in the "@include ag.grid-styles section" --> header-background-color: $light6
            background-color: $light6; // after the live config is removed, place this in the "@include ag.grid-styles section" --> header-background-color: $light6
            .ag-checkbox-input-wrapper::after {
                color: $dark10;
            }
            border: 1px solid $dark3;
        }

        .ag-sort-order {
            color: $dark10;
        }
    }
}
